import React from 'react';
import './App.css';

function App() {
    document.location = 'https://withjoy.com/sharvari-and-aj/welcome';
    return <></>;
    // return (
    //     <div className="App">
    //         <div className="App-header">
    //             <h1>Wedding!</h1>
    //             <h3>Coming August 24, 2024</h3>
    //         </div>
    //     </div>
    // );
}

export default App;
